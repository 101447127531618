import React from 'react'
import Layout from '../components/layout'
import styled from "@emotion/styled"

const Container = styled.div`
    text-align: center;
    margin-bottom: 100px;
    margin-top: 100px;
`

 const ThanksPage = () => (
  <Layout>
    <Container>
        <h1>Thank you for your message!</h1>
        <p>I usually get back to messages within a day or two. Looking forward to connecting with you.</p>
    </Container>
  </Layout>
)

export default ThanksPage